import React from 'react';

import { useAppContext } from '../../context/AppContext';

import WaitingToScan from '../WaitingToScan';
import UserContainer from '../container/UserContainer';
import PrinterContainer from '../printer/PrinterContainer';
// import LabelContainer from '../label/LabelContainer';
import LocationContainer from '../location/LocationContainer';
import Loading from '../Loading';

interface Props {

}

const Component: React.FC<Props> = () => {
    const { user, isLoading, selectedPrinter, selectedLocation, auth }: any = useAppContext();


    const DisplayComponent = () => {
        if (!selectedPrinter.id && auth.isMedicalCheckinAdmin) {
            return <PrinterContainer />;
        }
        // else if (!selectedLabel.id && auth.isMedicalCheckinAdmin) {
        //     return <LabelContainer />;
        // }
        else if (!selectedLocation.id && (auth.isMedicalCheckinAdmin || auth.isWaitTimeCheckin)) {
            return <LocationContainer />;
        }
        else if (!(user.id)) {
            return <WaitingToScan />;
        }

        return <UserContainer />
    }

    return (
        <div className='user-info'>
            <Loading loaded={isLoading.search} />
            <DisplayComponent />
        </div>


    );
}

export default Component;
