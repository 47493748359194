import BaseController from './BaseController';


export default class AttestationController extends BaseController {
    public questions: []

    constructor() {
        super();
        this.questions = [];
    }

    async getQuestions(): Promise<any> {

        if (this.questions.length === 0) {
            let response = await this.useFetch('GET', 'get/questions', `${this.apiHost}/${this.apiPath}/pass/questions`);

            if (response.status === 200) {
                let data = response.json();
                this.questions = data;
            } else {
                let text = await response.text();
                return Promise.reject(text);
            }
        }

        return this.questions;
    }

    async postAttestation(data: any, krb_name: string): Promise<any> {

        let today_start = new Date(),
            today_end = new Date();

        today_start.setHours(8, 0, 0, 0);
        today_end.setHours(17, 0, 0, 0);

        let body = {
            answers: data.answers,
            start_time: today_start,
            end_time: today_end
        }

        let response = await this.useFetch('POST', 'post/attestation', `${this.apiHost}/${this.apiPath}/pass/attestations`, body, null, null, krb_name);

        if (response.status === 200) {
            let data = response.json();
            return data;
        } else {
            let text = await response.text();
            return Promise.reject(text);
        }
    }

    async getStatus(krb_name: string): Promise<any> {

        let response = await this.useFetch('GET', 'get/status', `${this.apiHost}/${this.apiPath}/pass/status`, null, null, null, krb_name);

        if (response.status === 200) {
            let data = response.json();
            return data;
        } else {
            let text = await response.text();
            return Promise.reject(text);
        }

    }




}