import React from 'react';

import ListContainer from '../list/ListContainer';
import List from '../list/List';

import { useAppContext } from '../../context/AppContext';

interface Props {

}

const Component: React.FC<Props> = () => {
    let { printers, selectedPrinter, setSelectedPrinter }: any = useAppContext();

    const printerList =
        <List items={printers}
            selectedItem={selectedPrinter}
            updateSelected={setSelectedPrinter}
            localStorageId='printer_id' />;

    return (
        <ListContainer
            list={printerList}
            title='Select a printer'
        />
    );
}

export default Component;
