import React from 'react';
import Loader from 'react-loader';

interface Props {
    loaded: boolean
}

const Component: React.FC<Props> = ({ loaded }) => {

    return (

        <div className={"loading-container " + (!loaded ? 'hidden' : '')}>
            <Loader className={"spinner"} loaded={!loaded} color={"#8d3433"} />
        </div>

    );
}

export default Component;
