import React, { Fragment } from 'react';

import Barcode from './Barcode';
import PrinterButton from './printer/PrinterButton';
// import LabelButton from './label/LabelButton';
import LocationButton from './location/LocationButton';
import UserOptionButton from './button/UserOptionsButton';
import ScanSwitch from './ScanSwitch';

import { useAppContext } from '../context/AppContext';
import { Alert } from 'react-bootstrap';

interface Props {

}

const Component: React.FC<Props> = () => {
    const { profilePicture, selectedPrinter, auth, selectedLocation }: any = useAppContext();

    const getTitle = () => {
        if (auth.isMedicalCheckinAdmin) {
            return 'COVID Medical Swab Checkin'
        } else if (auth.isCheckinView) {
            return 'COVID Access Lookup'
        } else {
            return 'COVID Wait Line Checkin'
        }
    }

    return (
        <div className='header'>
            <div className="title-row">
                <h3>{getTitle()}</h3>
                <span className='avatar-container'>
                    {(selectedPrinter.id && auth.isMedicalCheckinAdmin) &&
                        <Fragment>
                            <ScanSwitch />
                            <PrinterButton />
                            {/* <LabelButton /> */}
                        </Fragment>
                    }
                    {(selectedLocation.id && (auth.isWaitTimeCheckin || auth.isMedicalCheckinAdmin)) &&
                        <Fragment>
                            <LocationButton />
                        </Fragment>
                    }
                    <img className='avatar' src={profilePicture} alt="" />
                    <UserOptionButton />
                </span>
            </div>
            {selectedLocation.id &&
                <div className='d-flex justify-content-center'>
                    <Alert variant='success' className='location-banner'>
                        <h5>
                            Checking in for {selectedLocation.name}
                        </h5>
                    </Alert>
                </div>
            }
            <div className='barcode-row'>
                {((selectedPrinter.id && selectedLocation.id) || auth.isCheckinView || (auth.isWaitTimeCheckin && selectedLocation.id)) && <Barcode />}
            </div>

        </div>


    );
}

export default Component;
