import React, { useState, useEffect } from 'react';

import { useAppContext } from '../../context/AppContext';
import PersonController from '../../api/controller/PersonController';
import { getInitialErrorText, validateUserDetails } from '../../common/Util';
import CheckinProvider from '../../api/CheckinProvider';
import Util from '../../common/Util';


import DatePicker from '../input/DatePicker';
import InputMask from '../input/InputMask';
import Input from '../input/Input';
import Select from '../input/Select';
import UploadButton from '../button/UploadButton';
import CloseButton from '../button/CloseButton';

import { Row, Col } from 'react-bootstrap';
import CloneDeep from 'clone-deep';
import cloneDeep from 'clone-deep';



interface Props {

}

const personController = new PersonController();

// const noData = <h5>No Data</h5>;

const Component: React.FC<Props> = () => {
    const { user, setUser, setEdit, setIsLoading, states, hideEdit }: any = useAppContext();
    const [state, setState]: any = useState(CloneDeep(user));
    const [errorText, setErrorText]: any = useState(getInitialErrorText(user));
    const [showCloseButton, setShowCloseButton]: any = useState(true);
    const checkinProvider: any = new CheckinProvider();

    const onInputChange = (value: any, key: string, property?: string) => {
        console.log(value);
        if (property) {
            setState((state: any) => ({ ...state, [key]: { ...state[key], [property]: value } }));
        } else {
            setState((state: any) => ({ ...state, [key]: value }));
        }

        setErrorText((state: any) => ({ ...state, [property ? property : key]: '' }))
    }

    const onPressClose = () => {
        setEdit(false);
    }

    const onPressUpload = () => {
        let valid = validateForm();

        let body = { ...state, phone: '+1' + getTrimmedPhone() }

        if (valid) {
            setIsLoading((state: any) => ({ ...state, search: true }));
            personController.updatePersonInfo(body)
                .then(data => {
                    setUser(state);
                    setIsLoading((state: any) => ({ ...state, search: false }));
                    setEdit(false);
                    return state;
                })
                .then(user => {
                    checkinProvider.handlePrintLabel(user);
                })
                .catch(error => {

                })
        }
    }

    const validateForm = () => {
        let [valid, newErrorText] = validateUserDetails(state, errorText);
        setErrorText(newErrorText);

        return valid;
    }

    const getTrimmedPhone = () => {
        let phone = cloneDeep(state.phone);
        if (state.phone && state.phone.indexOf("+") >= 0) {
            phone = state.phone.slice(2, state.phone.length);
        }
        return phone;
    }

    useEffect(() => {
        let valid = validateForm();
        setShowCloseButton(valid);
        // eslint-disable-next-line
    }, [user])



    return (
        <div className='user-edit'>

            <Row className='detail-row no-border'>
                <Col>
                    <span className='label'>Date of Birth</span>
                </Col>
                <Col className='detail-col'>
                    <DatePicker
                        value={state.date_of_birth}
                        placeholder=""
                        onChange={(value: any) => onInputChange(value, 'date_of_birth')}
                        className='date-picker'
                        errorText={errorText.date_of_birth}
                    />
                </Col>
            </Row>
            <Row className='detail-row no-border'>
                <Col>
                    <span className='label'>Phone</span>
                </Col>
                <Col className='detail-col'>
                    <InputMask
                        mask={'(999)999-9999'}
                        value={getTrimmedPhone()}
                        onChange={(value: any) => onInputChange(value, 'phone')}
                        errorText={errorText.phone} />
                </Col>
            </Row>
            <Row className='detail-row no-border'>
                <Col>
                    <span className='label'>Email</span>
                </Col>
                <Col className='detail-col'>
                    <Input
                        value={state.email}
                        onChange={(value: any) => onInputChange(value, 'email')}
                        errorText={errorText.email} />
                </Col>
            </Row>
            <Row className='detail-row no-border'>
                <Col>
                    <span className='label'>Address1</span>
                </Col>
                <Col className='detail-col'>
                    <Input
                        value={state.address.address1}
                        onChange={(value: any) => onInputChange(value, 'address', 'address1')}
                        errorText={errorText.address1} />
                </Col>
            </Row>
            <Row className='detail-row no-border'>
                <Col>
                    <span className='label'>Address2</span>
                </Col>
                <Col className='detail-col'>
                    <Input
                        value={state.address.address2}
                        onChange={(value: any) => onInputChange(value, 'address', 'address2')}
                        errorText={errorText.address2} />
                </Col>
            </Row>
            <Row className='detail-row no-border'>
                <Col>
                    <span className='label'>City</span>
                </Col>
                <Col className='detail-col'>
                    <Input
                        value={state.address.city}
                        onChange={(value: any) => onInputChange(value, 'address', 'city')}
                        errorText={errorText.city} />
                </Col>
            </Row>
            <Row className='detail-row no-border'>
                <Col>
                    <span className='label'>State</span>
                </Col>
                <Col className='detail-col'>
                    <Select
                        value={state.address.state}
                        onChange={(value: any) => onInputChange(value, 'address', 'state')}
                        items={states} />

                    {/* <Input
                        value={state.address.state}
                        onChange={(value: any) => onInputChange(value, 'address', 'state')}
                        errorText={errorText.state} /> */}
                </Col>
            </Row>
            <Row className='detail-row no-border'>
                <Col>
                    <span className='label'>Postal Code</span>
                </Col>
                <Col className='detail-col'>
                    <InputMask
                        mask={'99999'}
                        value={state.address.postal_code}
                        onChange={(value: any) => onInputChange(value, 'address', 'postal_code')}
                        errorText={errorText.postal_code} />
                </Col>
            </Row>
            {/* <Row className='detail-row no-border last-row'>
                <Col>
                    <span className='label'>Date Last Tested</span>
                </Col>
                <Col className='detail-col'>
                    {user.last_test_date ?
                        <h5>{getFormattedDateString(user.last_test_date)}</h5> :
                        <h5>{noData}</h5>}
                </Col>
            </Row> */}
            <Row className='detail-row last-row'>
                <Col>
                    <span className='label'>Acknowledgement</span>
                </Col>
                <Col className='detail-col'>
                    <h5>{user.acknowledge_status}</h5>
                </Col>
            </Row>
            <Row className='detail-row last-row'>
                <Col>
                    <span className='label'>Training</span>
                </Col>
                <Col className='detail-col'>
                    <h5>{user.training_status}</h5>
                </Col>
            </Row>
            <Row className='detail-row last-row'>
                <Col>
                    <span className='label'>Medical</span>
                </Col>
                <Col className='detail-col'>
                    {user.last_test_date ?
                        <h5>{Util.getFormattedDateString(user.last_test_date)}</h5> :
                        <h5>{user.medical_status}</h5>}
                </Col>
            </Row>
            <Row className='detail-row last-row'>
                <Col>
                    <span className='label'>Attestation</span>
                </Col>
                <Col className='detail-col'>
                    {user.attestion_submitted_on ?
                        <h5>{Util.getFormattedDateString(user.attestion_submitted_on)}</h5> :
                        <h5>{user.attestation_status}</h5>}
                </Col>
            </Row>
            <Row className='detail-row last-row'>
                <Col>
                    <span className='label'>Next Test Date</span>
                </Col>
                <Col className='detail-col'>
                    {user.next_medical_date ?
                        <h5>{Util.getFormattedDateString(user.next_medical_date)}</h5> :
                        <h5>None</h5>}
                </Col>
            </Row>
            {hideEdit &&
                <Row className='detail-row last-row no-border'>
                    <Col>
                        <span className='label'>Buildings</span>
                    </Col>
                    <Col className='detail-col'>
                        <h5>{user.buildings.map((item: any) => item.building_key).join(' ')}</h5>
                    </Col>
                </Row>
            }
            <Row className='action-container'>
                <UploadButton className='mr-2' onClick={onPressUpload} />
                {showCloseButton && <CloseButton onClick={onPressClose} />}

            </Row>

        </div>
    );



}

export default Component;
