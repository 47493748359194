import React from 'react';

import LocalStorage from '../../common/LocalStorage';
import Loading from '../Loading';

import { Table } from 'react-bootstrap';

interface Props {
    items: Array<any>
    selectedItem: any
    updateSelected: any
    localStorageId: string
    onClickListItem?: any
}

const localStorage: any = new LocalStorage();

const Component: React.FC<Props> = ({ items, selectedItem, updateSelected, localStorageId, onClickListItem }) => {

    const handleSelect = (item: any) => {
        updateSelected(item);
        localStorage.save(localStorageId, item);

        if (onClickListItem) {
            onClickListItem();
        }
    }

    return (
        <div style={{ position: 'relative' }}>
            <Loading loaded={items.length === 0} />
            <Table className='printer-list' hover borderless>
                <tbody>
                    {items.map((item: any) => (
                        <tr className={item.id === selectedItem.id ? 'selected' : ''} onClick={() => handleSelect(item)}>
                            <td>
                                {item.name}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>




    );
}

export default Component;
