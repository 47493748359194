import React from 'react';
import { useAppContext } from '../../context/AppContext';

import ListButton from '../list/ListButton';

interface Props {

}

const Component: React.FC<Props> = () => {
    let { selectedLocation, setSelectedLocation, locations }: any = useAppContext();

    return (
        <ListButton
            imgPath={'./assets/map-marker-alt.svg'}
            tooltip={'Location'}
            items={locations}
            selectedItem={selectedLocation}
            updateSelected={setSelectedLocation}
            localStorageId={'location_id'}
        />
    );
}

export default Component;
