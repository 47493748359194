import React from 'react';
import { useAppContext } from '../../context/AppContext';

import ListButton from '../list/ListButton';

interface Props {

}

const Component: React.FC<Props> = () => {
    let { selectedPrinter, setSelectedPrinter, printers }: any = useAppContext();

    return (
        <ListButton
            imgPath={'./assets/print.svg'}
            tooltip={'Printer'}
            items={printers}
            selectedItem={selectedPrinter}
            updateSelected={setSelectedPrinter}
            localStorageId={'printer_id'}
        />
    );
}

export default Component;
