import React from 'react';

import { Button } from 'react-bootstrap';

import { useAppContext } from '../../context/AppContext';



interface Props {
    className?: string
}

const Component: React.FC<Props> = ({ className }) => {
    const { setShowAttestationDialog }: any = useAppContext();

    const onClick = () => {
        setShowAttestationDialog(true);
    }

    return (
        <>
            <Button variant="outline-secondary" className={'button button-edit mb-3 ' + className} onClick={onClick} disabled={false}><img src="assets/check-square.svg" alt="" />Complete Attestation</Button>
        </>
    );
}

export default Component;

