import React, { Fragment } from 'react';

import { useAppContext } from '../../context/AppContext';
import Util from '../../common/Util';
import CheckinProvider from '../../api/CheckinProvider';

import ReprintButton from '../button/ReprintButton';

import { Row, Col } from 'react-bootstrap';

interface Props {

}

const noData = <h5>No Data</h5>;

const Component: React.FC<Props> = () => {
    const { user, edit, hideEdit, auth, pin }: any = useAppContext();
    const checkinProvider: any = new CheckinProvider();

    const handleReprint = () => {
        checkinProvider.handlePrintLabel();
    }

    return (
        <div className={'user-view'}>
            <Row className='detail-row'>
                <Col>
                    <span className='label'>Email</span>
                </Col>
                <Col className='detail-col'>
                    {user.email ?
                        <h5>{user.email}</h5> :
                        <h5>{noData}</h5>}
                </Col>
            </Row>
            {auth.isMedicalCheckinAdmin &&
                <Fragment>
                    <Row className='detail-row'>
                        <Col>
                            <span className='label'>Date of Birth</span>
                        </Col>
                        <Col className='detail-col'>
                            {user.date_of_birth ?
                                <h5>{Util.getFormattedDateString(user.date_of_birth)}</h5> :
                                <h5>{noData}</h5>}
                        </Col>
                    </Row>
                    <Row className='detail-row'>
                        <Col>
                            <span className='label'>Phone</span>
                        </Col>
                        <Col className='detail-col'>
                            {user.phone ?
                                <h5>{Util.getFormattedPhoneNumber(user.phone)}</h5> :
                                <h5>{noData}</h5>}
                        </Col>
                    </Row>
                    <Row className='detail-row'>
                        <Col>
                            <span className='label'>Address</span>
                        </Col>
                        <Col className='detail-col'>
                            {user.address.address1 ?
                                <Fragment>
                                    <h5>{user.address.address1}</h5>
                                    <h5> {user.address.address2}</h5>
                                    <h5>{user.address.city}, {user.address.state} {user.address.postal_code}</h5>
                                </Fragment> :
                                <h5>{noData}</h5>}
                        </Col>
                    </Row>
                </Fragment>}
            {auth.canManagePin && pin &&
                <Row className='detail-row'>
                    <Col>
                        <span className='label'>Pin</span>
                    </Col>
                    <Col className='detail-col'>
                        <h5>{pin === 'NO_PIN' ? 'None' : pin}</h5>
                    </Col>
                </Row>}
            {/* <Row className='detail-row last-row'>
                <Col>
                    <span className='label'>Date Last Tested</span>
                </Col>
                <Col className='detail-col'>
                    {user.last_test_date ?
                        <h5>{Util.getFormattedDateString(user.last_test_date)}</h5> :
                        <h5>{noData}</h5>}
                </Col>
            </Row>
            <Row className='detail-row no-border last-row'>
                <Col>
                    <span className='label'>Date Last Attestation</span>
                </Col>
                <Col className='detail-col'>
                    {user.attestion_submitted_on ?
                        <h5>{Util.getFormattedDateString(user.attestion_submitted_on)}</h5> :
                        <h5>{noData}</h5>}
                </Col>
            </Row> */}
            <Row className='detail-row last-row'>
                <Col>
                    <span className='label'>Acknowledgement</span>
                </Col>
                <Col className='detail-col'>
                    <h5>{user.acknowledge_status}</h5>
                </Col>
            </Row>
            <Row className='detail-row last-row'>
                <Col>
                    <span className='label'>Training</span>
                </Col>
                <Col className='detail-col'>
                    <h5>{user.training_status}</h5>
                </Col>
            </Row>
            <Row className='detail-row last-row'>
                <Col>
                    <span className='label'>Medical</span>
                </Col>
                <Col className='detail-col'>
                    {user.last_test_date ?
                        <h5>{Util.getFormattedDateString(user.last_test_date)}</h5> :
                        <h5>{user.medical_status}</h5>}
                </Col>
            </Row>
            <Row className='detail-row last-row'>
                <Col>
                    <span className='label'>Attestation</span>
                </Col>
                <Col className='detail-col'>
                    {user.attestion_submitted_on ?
                        <h5>{Util.getFormattedDateString(user.attestion_submitted_on)} {Util.get12HourTimeString(user.attestion_submitted_on.split(' ')[1])}</h5> :
                        <h5>{user.attestation_status}</h5>}
                </Col>
            </Row>
            <Row className='detail-row last-row'>
                <Col>
                    <span className='label'>Next Test Date</span>
                </Col>
                <Col className='detail-col'>
                    {user.next_medical_date ?
                        <h5>{Util.getFormattedDateString(user.next_medical_date)}</h5> :
                        <h5>None</h5>}
                </Col>
            </Row>
            <Row className='detail-row last-row'>
                <Col>
                    <span className='label'>Negative Test Required</span>
                </Col>
                <Col className='detail-col'>
                    {user.status && user.status.requirements && user.status.requirements.length > 0 && user.status.requirements[3].medical_awaiting_test_outcome ?
                        <h5>Yes</h5> :
                        <h5>No</h5>}
                </Col>
            </Row>
            {hideEdit &&
                <Row className='detail-row last-row no-border'>
                    <Col>
                        <span className='label'>Buildings</span>
                    </Col>
                    <Col className='detail-col'>
                        <h5>{user.buildings.map((item: any) => item.building_key).join(' ')}</h5>
                    </Col>
                </Row>
            }
            <div className={'reprint-container ' + (edit || hideEdit || user.too_early_for_test ? 'hidden' : '')}>
                <ReprintButton onClick={handleReprint} />
            </div>

        </div>
    )




}

export default Component;
