import React from 'react'

import Questions from './Questions';

import { useAppContext } from '../../context/AppContext';

import { Modal } from 'react-bootstrap';



interface Props {
    placeholder?: any
}

export const Dialog: React.FC<Props> = () => {
    const { user, showAttestationDialog, setShowAttestationDialog }: any = useAppContext();

    return (

        <Modal centered show={showAttestationDialog} onHide={() => setShowAttestationDialog(false)} className='dialog-attestation'>
            <Modal.Header closeButton>
                <Modal.Title >
                    <h5>Complete Attestation for: <br /> <strong>{user.displayName}</strong></h5>
                </Modal.Title>
            </Modal.Header>
            <Questions />
        </Modal>
    );
}