import React from 'react';


interface Props {

}

const Component: React.FC<Props> = () => {


    return (
        <div className='waiting-to-scan'>
            <img src="./assets/info-circle.svg" alt="Info" />
            <h4>Scan barcode or enter MIT ID / Kerb manually.</h4>
        </div>


    );
}

export default Component;
