import React from 'react';

interface Props {
    loaded: boolean
}

const Component: React.FC<Props> = ({ loaded }) => {

    return (

        <div className='logout'>
            <h2>Important Information: Logging out securely</h2>
            <br />
            <p>You are authenticated through <a href="http://ist.mit.edu/touchstone-detail" rel="noopener noreferrer" target="_blank">MIT Touchstone</a>. MIT Touchstone is a <a href="http://en.wikipedia.org/wiki/Single_sign-on" rel="noopener noreferrer" target="_blank">single sign-on</a> service that allows members of the MIT community to log in to participating MIT websites.
           Once you are authenticated via Touchstone, <b>the only way to log out of Touchstone is to close the browser.</b>
            </p>
            <p >
                Please contact the Help Desk at <a href="mailto:computing-help@mit.edu">computing-help@mit.edu</a> or
            3-1101 for assistance.
        </p>
        </div>

    );
}

export default Component;
