import React from 'react';

import { Button } from 'react-bootstrap';

interface Props {
    onClick: any,
    className?: string
}

const Component: React.FC<Props> = ({ className, onClick }) => {

    return (
        <Button variant="outline-secondary" className={'button button-edit mb-3 ' + className} onClick={onClick}><img src="assets/pencil.svg" alt="" />Edit</Button>
    );
}

export default Component;

