import React, { useState, Fragment } from 'react';

import { useAppContext } from '../../context/AppContext';
import AuthProvider from '../../api/AuthProvider';

import { Button, Table } from 'react-bootstrap';
import Popover from '@material-ui/core/Popover';

interface Props {

}

const authProvider = new AuthProvider();

const Component: React.FC<Props> = () => {
    const [anchorEl, setAnchorEl]: any = useState(null);
    const { profile }: any = useAppContext();

    const handleClose = () => {
        setAnchorEl(null);
    }

    const handleButtonPress = (event: any) => {
        setAnchorEl(event.target);
    }

    // const handleListItemPress = () => {
    //     setAnchorEl(null);
    // }

    const handleLogOut = () => {
        authProvider.logout()
            .then(data => {
                // debugger;
            })
            .catch(error => {
                // debugger;
            })
    }

    const open = Boolean(anchorEl);

    return (
        <Fragment>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}>
                <div className='printer-popover-container'>
                    <Table className='printer-list' hover borderless>
                        <tbody>
                            <tr onClick={handleLogOut}>
                                <td>
                                    Logout
                                </td>
                            </tr>

                        </tbody>
                    </Table>
                </div>
            </Popover>
            <Button variant="light" onClick={handleButtonPress} className='button user-option-btn'>{profile.display_name}</Button>
        </Fragment>



    );
}

export default Component;
