import React from 'react';

import ListContainer from '../list/ListContainer';
import List from '../list/List';

import { useAppContext } from '../../context/AppContext';

interface Props {

}

const Component: React.FC<Props> = () => {
    let { auth, locations, selectedLocation, setSelectedLocation }: any = useAppContext();

    let filtered_locations = auth.isMedicalCheckinAdmin ? locations.filter((item: any) => (item.provider === 'BROAD' && auth.canManageBroad) || (item.provider === 'QUEST' && auth.canManageQuest)) : locations

    const labelList =
        <List items={filtered_locations}
            selectedItem={selectedLocation}
            updateSelected={setSelectedLocation}
            localStorageId='location_id' />;

    return (
        <ListContainer
            list={labelList}
            title='Select a location'
        />
    );
}

export default Component;
