import React from 'react'

interface Props {
    value: any
    onChange: any
    items: any
}

const Component: React.FC<Props> = ({ value, onChange, items }) => {

    const onValueChange = (e: any) => {
        let value = e.target.value;

        if (onChange) {
            onChange(value);
        }
    }

    return (
        <select
            value={value}
            onChange={onValueChange}
            className='select'>
            {items.map((item: any) => <option value={item.id}>{item.name}</option>)}

        </select>
    )
}

export default Component;
