import { CovidPassRequirement, PassStatus, PassStatusModel, PrimaryAffiliatons, RequirementIds, RequirementStatus } from "../types/CovidStatusModel";

export default class StatusModel {
    status: PassStatus
    assignment_type: string
    primary_affiliation: PrimaryAffiliatons
    requirements: CovidPassRequirement[]

    constructor(personStatus: PassStatusModel) {
        this.status = personStatus.statusVal
        this.assignment_type = personStatus.assignment_type
        this.primary_affiliation = personStatus.primary_affiliation
        this.requirements = personStatus.requirements
    }

    getRequirement(id: RequirementIds): CovidPassRequirement | undefined {
        return this.requirements.find((r: CovidPassRequirement) => r.id === id)
    }

    hasMedicalIssue(): boolean {
        return this!.status === PassStatus.medical_symptoms ||
            this!.status === PassStatus.medical_positive ||
            this!.status === PassStatus.medical_block ||
            this!.status === PassStatus.medical_overdue
    }


    evalRequirement(id: RequirementIds, value: RequirementStatus): boolean {
        return this.getRequirement(id)!.status === value
    }

    allPrerequisitesMet(id: RequirementIds): [boolean, undefined | RequirementIds] {
        let allPrereqComp = true
        let firstFailedOn: undefined | RequirementIds = undefined
        if (this) {
            //if higher level statuses are fine, continue
            if (this.status === PassStatus.waiver_declined) {
                allPrereqComp = false
                firstFailedOn = RequirementIds.acknowledge
            } else if (this.hasMedicalIssue()) {
                allPrereqComp = false
                firstFailedOn = RequirementIds.medical
            }

            //no need to continue if already failed
            if (allPrereqComp) {
                this.getRequirement(id)!.prerequisites!.forEach((req: any) => {
                    if (this.getRequirement(req)!.status !== RequirementStatus.complete) {
                        //if medical result is pending, requirement is met for attestation
                        if (!(id === RequirementIds.attestation && req === RequirementIds.medical && this.getRequirement(req)!.status === RequirementStatus.pending)) {
                            allPrereqComp = false
                            if (!firstFailedOn)
                                firstFailedOn = req
                        }
                    }
                })
            }
        }

        if (allPrereqComp) {
            return [allPrereqComp, undefined]
        } else {
            return [false, firstFailedOn]
        }
    }

    validateAllRequirements(): boolean {
        let requirementsMet = true;
        let hasMedicalIssue = this.hasMedicalIssue();

        if (hasMedicalIssue)
            requirementsMet = false;

        this.requirements.forEach(item => {
            if (item.required) {
                if (item.id === 'medical' && item.status !== 'incomplete') {
                    //requirements completed
                } else if (item.status !== 'complete' && item.id !== 'attestation') {
                    requirementsMet = false;
                }
            }
        });





        return requirementsMet;
    }
}