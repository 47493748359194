import React, { Fragment } from 'react';

import ValidationError from './ValidationError';




interface Props {
    onChange: any
    placeholder?: string
    value: any
    className?: string
    errorText?: string
}

const Component: React.FC<Props> = ({ className, errorText, onChange, placeholder, value }) => {

    const onValueChange = (e: any) => {
        let value = e.target.value;

        if (onChange) {
            onChange(value);
        }
    }

    return (
        <Fragment>
            <input
                type="text"
                value={value}
                placeholder={placeholder ? placeholder : ''}
                onChange={onValueChange}
                className={className ? className : ''} />
            <ValidationError text={errorText} />
        </Fragment>
    );

}

export default Component;
