import React from 'react';

import { useAppContext } from '../../context/AppContext';

import Loading from '../Loading';
import EditButton from '../button/EditButton';
import AttestationButton from '../button/AttestationButton';
import PinButton from '../button/PinButton';
// import RequirementIcons from '../RequirementIcons';

interface Props {

}

const Component: React.FC<Props> = () => {
    const { auth, edit, setEdit, hideEdit, user, userPicture, clearMessages, isLoading, showAttestationButton }: any = useAppContext();


    const onPressEdit = () => {
        setEdit(true);
        clearMessages();
    }

    return (
        <div className='user-picture'>

            <div className="image-container">
                {!isLoading.picture && <img className='user-picture-img' src={userPicture} alt="User" />}
                <Loading loaded={isLoading.picture} />
            </div>
            <h3 className='mt-2 mb-2'>{user.displayName}</h3>
            {/* <RequirementIcons /> */}
            <EditButton onClick={onPressEdit} className={edit || hideEdit ? 'hidden' : ''} />

            {showAttestationButton && !edit && <AttestationButton />}
            {auth.canManagePin && !edit && <PinButton />}


        </div>
    );
}

export default Component;
