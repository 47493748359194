import React from 'react';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface Props {
    children: any
    text: string
}

const Component: React.FC<Props> = ({ children, text }) => {

    const renderTooltip = (props: any, text: string) => (
        <Tooltip {...props}>{text}</Tooltip>
    );


    return (
        <OverlayTrigger placement="top" overlay={(props: any) => renderTooltip(props, text)}>
            {children}
        </OverlayTrigger>
    )


}

export default Component;