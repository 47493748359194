import React from 'react';

import { Button } from 'react-bootstrap';

import { useAppContext } from '../../context/AppContext';
import PersonController from '../../api/controller/PersonController';

const personController = new PersonController();

interface Props {
    className?: string
}

const Component: React.FC<Props> = ({ className }) => {
    const { setPin, user, setMessages, setIsLoading }: any = useAppContext();

    const onClick = () => {
        setIsLoading((state: any) => ({ ...state, search: true }));
        personController.resetPin(user.id)
            .then(data => {
                if (data.success) {
                    setPin(data.pin);
                    setMessages((state: any) => ({
                        ...state, user: {
                            id: 1,
                            type: 'success',
                            message: 'Pin has been reset'
                        }
                    }))
                } else {
                    setMessages((state: any) => ({
                        ...state, user: {
                            id: 1,
                            type: 'danger',
                            message: 'Failed to reset pin'
                        }
                    }))
                }
                setIsLoading((state: any) => ({ ...state, search: false }));
            })
            .catch(error => {
                setMessages((state: any) => ({
                    ...state, user: {
                        id: 1,
                        type: 'danger',
                        message: error
                    }
                }))
                setIsLoading((state: any) => ({ ...state, search: false }));
            })
    }

    return (
        <>
            <Button variant="outline-secondary" className={'button button-edit ' + className} onClick={onClick} disabled={false}><img src="assets/check-square.svg" alt="" />Reset Pin</Button>
        </>
    );
}

export default Component;

