import React, { useState, useEffect } from 'react';

import './scss/main.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

import { useAppContext } from './context/AppContext';
import AuthProvider from './api/AuthProvider';
import BaseController from './api/controller/BaseController';
import LocalStorage from './common/LocalStorage';

import MainContainer from './components/container/MainContainer';
import SplashScreen from './components/SplashScreen';


const authProvider = new AuthProvider();
const baseController = new BaseController();
const localStorage = new LocalStorage();

const App: React.FC = () => {
    const [isLoading, setIsLoading]: any = useState(true);
    const { initializeApp, initializeProfile, initializeProfilePicutre, auth, initializePrinters, setSelectedPrinter, setScanType, initializeLocations }: any = useAppContext();

    useEffect(() => {

        let scanType = localStorage.get('scan_type');
        if (scanType) {
            setScanType(scanType);
        }
        // eslint-disable-next-line
    }, []);


    useEffect(() => {
        authProvider.getToken()
            .then(data => {

                return data.user;
            })
            .then(user => {
                baseController.getUserAuth()
                    .then(data => {
                        setIsLoading(false);
                        initializeApp({ ...data, loggedInUser: user });

                        //save printers
                        let printers = data.lookups.printers;
                        initializePrinters(printers);
                        let localStoragePrinter = localStorage.get('printer_id') ? localStorage.get('printer_id') : {};
                        let printer = printers.filter(item => item.id === localStoragePrinter.id)[0];
                        if (printer) {
                            setSelectedPrinter(printer);
                        } else {
                            localStorage.delete('printer_id');
                        }
                        //save locations
                        let locations = data.lookups.locations;
                        initializeLocations(locations);
                        let localStorageLabel = localStorage.get('location_id') ? localStorage.get('location_id') : {};
                        let label = locations.filter(item => item.id === localStorageLabel.id)[0];
                        if (label) {
                            // setSelectedLocation(label);
                        } else {
                            localStorage.delete('location_id');
                        }
                    })
                    .catch(error => {
                        setIsLoading(false);
                    })

                baseController.getProfile()
                    .then(data => {
                        initializeProfile(data);
                    })
                baseController.getProfilePicture()
                    .then(data => {
                        initializeProfilePicutre(data);
                    })
            })
        // eslint-disable-next-line
    }, [])

    if (isLoading) {
        return (
            <SplashScreen message='Loading...' />
        )
    } else if (!auth.isMedicalCheckinAdmin && !auth.isCheckinView && !auth.isWaitTimeCheckin) {
        return (
            <SplashScreen message='Not Authorized' />
        )
    } else {
        return <MainContainer />
    }

}

export default App;


