import moment from 'moment';
import cloneDeep from 'clone-deep';

export const getDateString = (date: Date) => {
    return date.toISOString().split('T')[0];
}

export const getDefaultEndDateString = () => {
    let date = new Date();
    let month = date.getUTCMonth();
    let year = date.getUTCFullYear();

    //if decemeber return end of next year
    return `${month === 11 ? year + 1 : year}-12-31`;

}

export const getFormattedDateString = (date: string) => {
    let aDate;
    if (date) {
        date = date.split('T')[0];
        date = date.split(' ')[0];
        aDate = date.split('-');
        return `${aDate[1]}/${aDate[2]}/${aDate[0]}`;
    }
    return
}


export const get12HourTimeString = (time: string) => {
    let date = new Date();
    let options = {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    };
    if (time) {

        date.setHours(parseInt(time.split(':')[0]));
        date.setMinutes(parseInt(time.split(':')[1]));


        let timeString = date.toLocaleString('en-US', options);

        return timeString;
    }
    return time;
}

export const toUtc = (localTime: string): string => {
    return moment.utc(moment(localTime)).format();
}

export const getDayOfWeekName = (day_of_week: number) => {

    switch (day_of_week) {
        case 7: return 'Sunday';
        case 1: return 'Monday';
        case 2: return 'Tuesday';
        case 3: return 'Wednesday';
        case 4: return 'Thursday';
        case 5: return 'Friday';
        case 6: return 'Saturday';
    }
}

export const getInitialErrorText = (object: any) => {
    let text: any = {};

    Object.keys(object).map((key) => {
        text[key] = '';
        return key;
    })

    return text;
}

export const getFormattedPhoneNumber = (phone: string) => {
    if (phone.length > 9) {
        phone = phone.substr(phone.length - 10, phone.length);
    }
    let areaCode = phone.substr(0, 3),
        firstDigits = phone.substr(3, 3),
        lastDigits = phone.substr(6, phone.length);

    console.log(areaCode, firstDigits, lastDigits);

    return `(${areaCode})${firstDigits}-${lastDigits}`;
}


export const validateUserDetails = (user: any, errorText: any) => {
    let validationText = cloneDeep(errorText),
        //eslint-disable-next-line
        emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
        valid = true;

    if (!user.date_of_birth) {
        validationText.date_of_birth = 'Enter a valid date of birth';
        valid = false;
    }

    if (!user.phone) {
        validationText.phone = 'Enter a valid phone number';
        valid = false;
    }

    if (!user.email || !emailRegex.test(user.email)) {
        validationText.email = 'Enter a valid email';
        valid = false;
    }

    if (!user.address.address1) {
        validationText.address1 = 'Enter a valid address';
        valid = false;
    }

    if (!user.address.city) {
        validationText.city = 'Enter a valid city';
        valid = false;
    }

    if (!user.address.state) {
        validationText.state = 'Enter a valid state';
        valid = false;
    }

    if (!user.address.postal_code) {
        validationText.postal_code = 'Enter a valid postal code';
        valid = false;
    }

    return [valid, validationText];;
}


export default {
    getDateString,
    getDefaultEndDateString,
    get12HourTimeString,
    getFormattedDateString,
    toUtc,
    getDayOfWeekName,
    getInitialErrorText,
    getFormattedPhoneNumber,
    validateUserDetails
};