export enum RequirementIds {
    acknowledge = 'acknowledge',
    training = 'training',
    medical = 'medical',
    attestation = 'attestation'
}

export enum RequirementStatus {
    complete = 'complete',
    incomplete = 'incomplete',
    pending = 'pending',
    due_soon = 'due_soon',
    overdue_grace = 'overdue_grace',
}

export enum PassStatus {
    access_granted = "access_granted",
    requirements_incomplete = "requirements_incomplete",
    medical_block = "medical_block",
    no_access = "no_access",
    medical_symptoms = "medical_symptoms",
    medical_positive = "medical_positive",
    medical_overdue = "medical_overdue",
    waiver_declined = "waiver_declined"
}

export enum PrimaryAffiliatons {
    student = "student",
    staff = "staff",
    affiliate = "affiliate"
}

export interface CovidPassRequirement {
    id: RequirementIds;
    required: boolean;
    visible: boolean;
    status: RequirementStatus;
    title_web: string;
    last_completion?: string;
    next_completion?: string;
    prerequisites?: string[];
}

export interface PassStatusModel {
    statusVal: PassStatus;
    assignment_type: "research";
    primary_affiliation: PrimaryAffiliatons;
    requirements: CovidPassRequirement[];
}