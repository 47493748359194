import React, { useState, Fragment } from 'react';

import List from './List';
import Tooltip from '../Tooltip';

import { Button } from 'react-bootstrap';
import Popover from '@material-ui/core/Popover';

interface Props {
    imgPath: string
    tooltip: string
    items: Array<any>
    selectedItem: any
    updateSelected: any
    localStorageId: string
}

const Component: React.FC<Props> = ({ imgPath, tooltip, items, selectedItem, updateSelected, localStorageId }) => {
    const [anchorEl, setAnchorEl]: any = useState(null);

    const handleClose = () => {
        setAnchorEl(null);
    }

    const handleButtonPress = (event: any) => {
        setAnchorEl(event.target);
    }

    const handleListItemPress = () => {
        setAnchorEl(null);
    }

    const open = Boolean(anchorEl);

    return (
        <Fragment>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}>
                <div className='printer-popover-container'>
                    <List items={items} selectedItem={selectedItem} updateSelected={updateSelected} localStorageId={localStorageId} onClickListItem={handleListItemPress} />
                </div>
            </Popover>
            <Tooltip
                text={tooltip}>
                <Button variant="light" onClick={handleButtonPress} className='printer-button'><img src={imgPath} alt={tooltip} /></Button>
            </Tooltip>

        </Fragment>



    );
}

export default Component;
