import React, { Fragment } from 'react';

import { useAppContext } from '../../context/AppContext';

import { Row, Alert } from 'react-bootstrap';


interface Props {

}

const Component: React.FC<Props> = () => {
    const { messages, setMessages }: any = useAppContext();



    return (
        <Fragment>
            {messages.user.id &&
                <Row >
                    <Alert className='message-container' key={'message'} variant={messages.user.type} show={messages.user} onClose={() => setMessages((state: any) => ({ ...state, user: '' }))} dismissible>
                        {messages.user.message}
                    </Alert>
                </Row>}
        </Fragment>

    );
}

export default Component;
