import React from 'react';

import { useAppContext } from '../../context/AppContext';
import { Button } from 'react-bootstrap';

interface Props {
    onClick: any,
    className?: string
}

const Component: React.FC<Props> = ({ className, onClick }) => {

    const { autoPrint }: any = useAppContext();

    return (
        <Button variant="outline-secondary" className={'button button-edit ' + className} onClick={onClick}><img src="assets/print.svg" alt="" />{autoPrint ? 'Reprint' : 'Print'} Label</Button>
    );
}

export default Component;

