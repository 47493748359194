import React from 'react';





interface Props {
    text?: string
}

const Component: React.FC<Props> = ({ text }) => {

    if (!text) {
        return <span></span>;
    } else {
        return (
            <div className='validation-error'>
                {text}
            </div>
        )
    }



}

export default Component;
