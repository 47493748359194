import React from 'react';

import Header from '../Header';
import ScannedContainer from './ScannedContainer';
import { Dialog } from '../attestation/Dialog';
import { useAppContext } from '../../context/AppContext';


interface Props {

}

const Component: React.FC<Props> = () => {
    const { hideEdit }: any = useAppContext();

    return (
        <div className='main'>
            {hideEdit && <Dialog />}
            <div className='left-container'>
                <img src="./assets/atlas-logo.svg" alt="Atlas Icon" />
            </div>
            <div className='right-container'>
                <Header />
                <ScannedContainer />

            </div>
        </div>


    );
}

export default Component;
