import React, { useState, createContext } from 'react';

import States from '../api/mock/States';
import { Loading } from '../types/Loading';
import { User } from '../types/User';
import { Lookup } from '../types/Lookup';


export const AppContext = createContext({});

interface Props {
    children: React.ReactNode
}

export const AppProvider: React.FC<Props> = ({ children }) => {
    const [auth, setAuth]: any = useState({
        loggedInUser: '',
        isCheckinView: false,
        isMedicalCheckinAdmin: false,
        isWaitTimeCheckin: false
    });
    const [isLoading, setIsLoading] = useState<Loading>({
        search: false,
        picture: false,
        submit: false
    });
    const [states, setStates] = useState<Lookup[]>(States);
    const [user, setUser] = useState<User | Object>({});
    const [userPicture, setUserPicture] = useState<Object>({});
    const [edit, setEdit] = useState<Boolean>(false);
    const [hideEdit, setHideEdit] = useState<Boolean>(false);
    const [profile, setProfile] = useState<Object>({});
    const [profilePicture, setProfilePicture]: any = useState(null);
    const [selectedPrinter, setSelectedPrinter]: any = useState({ id: null });
    const [printers, setPrinters]: any = useState([]);
    const [selectedLabel, setSelectedLabel]: any = useState({ id: null });
    const [labels, setLabels]: any = useState([]);
    const [selectedLocation, setSelectedLocation]: any = useState({ id: null });
    const [locations, setLocations]: any = useState([]);
    const [messages, setMessages]: any = useState({
        user: {}
    })
    const [scanType, setScanType]: any = useState('medical');
    const [showAttestationDialog, setShowAttestationDialog] = useState<boolean>(false);
    const [showAttestationButton, setShowAttestationButton] = useState<boolean>(false);
    const [autoPrint, setAutoPrint] = useState<boolean>(false);
    const [pin, setPin] = useState<string | null>(null);




    const initializeApp = (data: any) => {
        setAuth(data);
    }

    const initializeProfile = (data: any) => {
        setProfile(data);
    }

    const initializeProfilePicutre = (data: any) => {
        let url = URL.createObjectURL(data);
        setProfilePicture(url);
    }

    const initializePrinters = (data: any) => {
        setPrinters(data);
    }

    const initializeLabels = (data: any) => {
        setLabels(data);
    }

    const initializeLocations = (data: any) => {
        setLocations(data);
    }

    const clearMessages = () => {
        setMessages({ user: {} })
    }

    const exportValue = {
        initializeApp,
        initializeProfile,
        initializeProfilePicutre,
        initializePrinters,
        initializeLabels,
        initializeLocations,
        clearMessages,
        auth,
        states,
        setStates,
        isLoading,
        setIsLoading,
        user,
        setUser,
        userPicture,
        setUserPicture,
        edit,
        setEdit,
        profile,
        profilePicture,
        printers,
        selectedPrinter,
        setSelectedPrinter,
        labels,
        setLabels,
        selectedLabel,
        setSelectedLabel,
        messages,
        setMessages,
        hideEdit,
        setHideEdit,
        scanType,
        setScanType,
        showAttestationDialog,
        setShowAttestationDialog,
        showAttestationButton,
        setShowAttestationButton,
        locations,
        selectedLocation,
        setSelectedLocation,
        autoPrint,
        setAutoPrint,
        pin, setPin
    }

    return (
        <AppContext.Provider value={exportValue} >
            {children}
        </AppContext.Provider >
    )

}

export const useAppContext = () => {
    const context = React.useContext(AppContext)
    if (context === undefined) {
        throw new Error('useAppContext must be used within a AppProvider')
    }
    return context
}
