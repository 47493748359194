import React, { useEffect, useRef, useState } from 'react';

import { useAppContext } from '../context';
import PersonController from '../api/controller/PersonController';
import CheckinProvider from '../api/CheckinProvider';
import StatusModel from '../model/StatusModel';

import ValidationError from './input/ValidationError';
import { validateUserDetails } from '../common/Util';
import SearchButton from './button/SearchButton';

interface Props {
    autoFocus?: boolean
}

const personController = new PersonController();

const Component: React.FC<Props> = ({ autoFocus }) => {
    const barcodeInput = useRef(null);
    const [userId, setUserId]: any = useState('');
    const [errorText, setErrorText]: any = useState('');
    const { edit, setEdit, setUser, setUserPicture, isLoading, setIsLoading, clearMessages, setHideEdit, auth, setMessages, setShowAttestationButton, selectedLocation, setAutoPrint, setPin }: any = useAppContext();
    const checkinProvider: any = new CheckinProvider();



    const focusBarcodeInput = (event: any) => {
        if (barcodeInput && !edit) {
            //@ts-ignore
            barcodeInput.current.focus();
        }
    }

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            onPressSearch();
        }
    }

    const onInputChange = (event: any) => {
        let value = event.target.value;
        setUserId(value)
        setErrorText('');

    }

    const onPressSearch = () => {
        if (isLoading.search) {
            //maybe show msg to use that must wait for first scan to return before scanning again
        } else {
            setIsLoading((state: any) => ({ ...state, search: true, picture: true }));
            setUser({});
            setUserPicture(null);
            clearMessages();
            setPin(null);
            personController.getPersonInfo(userId, selectedLocation.id)
                .then((data: any) => {
                    setUser(data);
                    if (auth.isMedicalCheckinAdmin) {
                        if (!data.address.state)
                            data.address.state = 'MA';
                        let [valid] = validateUserDetails(data, {});
                        let autoPrint = checkinProvider.checkAutoPrint(data);
                        if (valid && data.newReservation) {
                            //print label
                            if (autoPrint) {
                                checkinProvider.handlePrintLabel(data, false);
                            }
                            setHideEdit(false);
                        } else {
                            setHideEdit(false);
                        }
                        setAutoPrint(autoPrint);
                        setEdit(!valid);
                    } else if (auth.isCheckinView) {
                        setHideEdit(true);
                        if (data.canSubmitAttestation) {
                            //check if show attestation
                            let statusModel = new StatusModel(data.status);
                            let requirementsMet = statusModel.validateAllRequirements();
                            setShowAttestationButton(requirementsMet);
                        } else {
                            setShowAttestationButton(false);
                        }

                    } else {
                        setMessages((state: any) => ({
                            ...state, user: {
                                id: 1,
                                type: 'success',
                                message: `${data.displayName} checked into waiting line.`
                            }
                        }))
                    }
                    if (auth.canManagePin) {
                        personController.getPin(data.id)
                            .then(data => {
                                setPin(data.pin);
                                setIsLoading((state: any) => ({ ...state, search: false }));
                            })
                            .catch(error => {
                                setMessages((state: any) => ({
                                    ...state, user: {
                                        id: 1,
                                        type: 'danger',
                                        message: 'Not authorized to view pin'
                                    }
                                }))
                                setIsLoading((state: any) => ({ ...state, search: false }));
                            })
                    } else {
                        setIsLoading((state: any) => ({ ...state, search: false }));
                    }

                    return data.id;
                })
                .then((mit_id: any) => {
                    personController.getPersonPicture(mit_id)
                        .then((data: any) => {
                            let url = URL.createObjectURL(data);
                            setUserPicture(url);
                            setUserId('');
                            setIsLoading((state: any) => ({ ...state, picture: false }));
                        })
                })
                .catch((error: any) => {
                    if (typeof error === 'string') {
                        setIsLoading((state: any) => ({ ...state, search: false, picture: false }));
                        setErrorText(error);
                    }
                })
        }
    }

    const checkAccessionNumber = (id: string) => {
        let accession_regex = /10-[0-9][0-9]-[0-9][0-9][0-9]-[0-9][0-9][0-9][0-9]/g;

        return accession_regex.test(id);
    }


    useEffect(() => {
        document.addEventListener("keydown", focusBarcodeInput);

        return () => {
            document.removeEventListener("keydown", focusBarcodeInput);
        }
        // eslint-disable-next-line
    }, [edit]);

    useEffect(() => {
        if (userId) {
            if ( (userId.startsWith('SP21-') && userId.length === 14) || parseInt(userId).toString().length === 9 || checkAccessionNumber(userId)) {
                onPressSearch();
            }
        }
        // eslint-disable-next-line
    }, [userId])

    return (
        <div className='barcode-container'>
            <div>
                <div className='barcode'>
                    <img src="assets/barcode.svg" alt="barcode" />
                    <input ref={barcodeInput} type="text" placeholder='Barcode / MIT ID / Kerb' value={userId} onChange={onInputChange} onKeyDown={handleKeyDown} />
                </div>
                <ValidationError text={errorText} />
            </div>
            <SearchButton onClick={onPressSearch} />
        </div>
    );
}

export default Component;
