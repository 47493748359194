import BaseController from './BaseController';

export default class PersonController extends BaseController {


    async getPersonInfo(id: string, location_id: string): Promise<any> {

        let response = await this.useFetch('GET', 'get/person', `${this.apiHost}/${this.apiPath}/checkin?q=${id}&location_id=${location_id}`);

        if (response.status === 200) {
            let data = response.json();
            return data;
        } else {
            let text = await response.text();
            return Promise.reject(text);
        }

    }

    async updatePersonInfo(body: any): Promise<any> {

        let response = await this.useFetch('PUT', 'put/person', `${this.apiHost}/${this.apiPath}/checkin`, body);

        if (response.status === 200) {
            let data = response.json();
            return data;
        } else {
            let text = await response.text();
            return Promise.reject(text);
        }

    }

    async getPersonPin(mit_id: string | undefined = undefined): Promise<any> {

        let endpoint = `${this.apiHostDigitalId}/${this.apiPathDigitalId}/pin`;

        if(mit_id)
            endpoint += "?mit_id=" + mit_id;

        let response = await this.useFetch('GET', 'get/pin', endpoint);

        if (response.status === 200) {
            let data = response.json();
            return data;
        } else {
            let text = await response.text();
            return Promise.reject(text);
        }
    }

    async getPersonPinCreate(mit_id: string | undefined = undefined): Promise<any> {

        let endpoint = `${this.apiHostDigitalId}/${this.apiPathDigitalId}/pin/create`;

        if(mit_id)
            endpoint += "?mit_id=" + mit_id;

        let response = await this.useFetch('GET', 'get/pin/create', endpoint);

        if (response.status === 200) {
            let data = response.json();
            return data;
        } else {
            let text = await response.text();
            return Promise.reject(text);
        }
    }

    async getPersonPicture(mit_id: string): Promise<any> {

        let response = await this.useFetch('GET', 'get/picture', `${this.apiHost}/${this.apiPathDigitalId}/picture/${mit_id}?resolution=high`);
        if (response.status === 200) {
            return response.blob();
        } else {
            let text = await response.text();
            return Promise.reject(text);
        }

    }

    async printLabel(body: any, printer_id: string, label_id: string, scanType: string, location_id: string): Promise<any> {

        let response = await this.useFetch('POST', 'post/print', `${this.apiHost}/${this.apiPath}/checkin/printers/${printer_id}/print?scan_type=${scanType}&location_id=${location_id}`, body);
        if (response.status === 200) {
            let data = response.json();
            return data;
        } else {
            let text = await response.text();
            return Promise.reject(text);
        }

    }

    async completeTest(body: any): Promise<any> {

        let response = await this.useFetch('POST', 'post/completed', `${this.apiHost}/${this.apiPath}/tests/complete`, body);
        if (response.status === 200) {
            let data = response.json();
            return data;
        } else {
            let text = await response.text();
            return Promise.reject(text);
        }

    }

    async getPin(mit_id): Promise<any> {

        let response = await this.useFetch('GET', 'profile', `${this.apiHost}/${this.apiPathDigitalId}/pin?mit_id=${mit_id}`);

        if (response.status === 200) {
            let data = response.json();
            return data;
        } else {
            let text = await response.text();
            return Promise.reject(text);
        }
    }

    async resetPin(mit_id): Promise<any> {

        let response = await this.useFetch('GET', 'profile', `${this.apiHost}/${this.apiPathDigitalId}/pin/create?mit_id=${mit_id}`);

        if (response.status === 200) {
            let data = response.json();
            return data;
        } else {
            let text = await response.text();
            return Promise.reject(text);
        }
    }




}
