import React from 'react';

import { Button } from 'react-bootstrap';

interface Props {
    onClick: any,
    className?: string
}

const Component: React.FC<Props> = ({ className, onClick }) => {

    return (
        <Button className={'button button-upload ' + className} onClick={onClick}><img src="assets/cloud-upload.svg" alt="" />Update</Button>
    );
}

export default Component;

