import React from 'react';

interface Props {
    list: any
    title?: string
}

const Component: React.FC<Props> = ({ list, title }) => {

    return (
        <div className='printer-container'>
            {title &&
                <h4 className='title'>{title}</h4>
            }
            {list}

        </div>
    );
}

export default Component;
