
import { useAppContext } from '../context/AppContext';
import PersonController from './controller/PersonController';

import moment from 'moment';


class CheckinProvider {
    appContext: any;
    personController: any

    constructor() {
        this.appContext = useAppContext();
        this.personController = new PersonController();
    }

    public handlePrintLabel = (passedUser?: any, setLoading = true) => {
        // eslint-disable-next-line
        let date = moment(new Date);
        let { user, selectedPrinter, selectedLabel, setMessages, scanType, selectedLocation } = this.appContext;

        if (passedUser) {
            user = passedUser;
        }

        let body = {
            kerberos: user.krb_name,
            accession_number: user.accession_number,
            remote_test: user.remote_testing
        }

        this.setLoading(setLoading, true);
        this.personController.printLabel(body, selectedPrinter.id, selectedLabel.id, scanType, selectedLocation.id)
            .then((data: any) => {
                // setUser((state: any) => ({ ...state, last_test_date: body.date }))
                this.setLoading(setLoading, false);

                const message = user.remote_testing ? `${user.displayName}' testing kit received. Label printing.` : `${user.displayName} checked in for testing. Label printing.`

                setMessages((state: any) => ({
                    ...state, user: {
                        id: 1,
                        type: 'success',
                        message: message
                    }
                }))
            })
            .catch((error: any) => {
                this.setLoading(setLoading, false);
                setMessages((state: any) => ({
                    ...state, user: {
                        id: 1,
                        type: 'danger',
                        message: error
                    }
                }))

            })
    }

    public handleCompleteTest = (passedUser: any, accession_number: string, setLoading = true) => {
        // eslint-disable-next-line
        let date = moment(new Date);
        let { user, setUser, setMessages } = this.appContext;

        if (passedUser) {
            user = passedUser;
        }

        let body = {
            kerberos: user.krb_name,
            date: date.format('YYYY-MM-DD'),
            accession_number: accession_number
        };

        this.setLoading(setLoading, true);
        this.personController.completeTest(body)
            .then((data: any) => {
                setUser((state: any) => ({ ...state, last_test_date: body.date }))
                this.setLoading(setLoading, false);
                setMessages((state: any) => ({
                    ...state, user: {
                        id: 1,
                        type: 'success',
                        message: `${user.displayName} test recorded as completed`
                    }
                }))
            })
            .catch((error: any) => {
                this.setLoading(setLoading, false);
                setMessages((state: any) => ({
                    ...state, user: {
                        id: 1,
                        type: 'danger',
                        message: error
                    }
                }))

            })


    }

    private setLoading(setLoading: boolean, loading: boolean) {
        let { setIsLoading } = this.appContext;
        if (setLoading) {
            setIsLoading((state: any) => ({ ...state, search: loading }));
        }
    }

    public checkAutoPrint(data: any) {
        let { setMessages } = this.appContext;

        let autoPrint = true,
            message = '';

        if (!data.hasAllocation) {
            autoPrint = false;
            message = `${data.displayName} does not have an active allocation in covid access. Label did not automatically print.`
        } else if (data.status.requirements[0].status !== 'complete' && data.status.requirements[0].required) {
            autoPrint = false;
            message = `${data.displayName} has not completed the acknowledgement form in covid pass. Label did not automatically print.`;
        //} else if (!data.status.requirements[2].required) {
        //    autoPrint = false;
        //    message = `${data.displayName} has an allocation type in covid access that does not require a medical test. Label did not automatically print.`;
        } else if (!data.hasIrregularSchedule && this.isWeekend()) {
            autoPrint = false;
            message = `${data.displayName} does not have an irregular schedule and should not be tested on weekends. Label did not automatically print.`;
        } else if (data.too_early_for_test) {
            autoPrint = false;
            message = `${data.displayName} is not eligible for testing. Test date is > 30 days in future.`;
        } else if (data.on_vaccine_hold) {
            autoPrint = false;
            message = `${data.displayName} is not eligible for testing. Currently on vaccine hold.`;
        }

        if (!autoPrint) {
            setMessages((state: any) => ({
                ...state, user: {
                    id: 1,
                    type: 'danger',
                    message: message
                }
            }))
        }

        return autoPrint;
    }

    private isWeekend() {
        let today = new Date(),
            day = today.getDay();

        return day === 6 || day === 0;
    }
}


export default CheckinProvider;
