import React from 'react';

import { useAppContext } from '../../context/AppContext';
// import PrinterProvider from '../../api/PrinterProvider';

import UserPicture from '../user/UserPicture';
import UserInfo from '../user/UserView';
import UserEdit from '../user/UserEdit';
import UserMessage from '../user/UserMessage';
// import UserPin from '../user/UserPin';
// import CheckInButton from '../button/CheckInButton';

import { Row, Col } from 'react-bootstrap';

interface Props {

}

const Component: React.FC<Props> = () => {
    const { edit, hideEdit, auth }: any = useAppContext();
    // const printerProvider: any = new PrinterProvider();

    return (
        <>

            <div className='user-info'>
                <div className='scanned-container'>
                    <UserMessage />
                    {(auth.isMedicalCheckinAdmin || auth.isCheckinView) &&
                        <Row >
                            {/* Left */}
                            <Col xs='12' md='5'>
                                <UserPicture />
                                {/* <UserPin /> */}
                            </Col>

                            {/* Right */}
                            <Col xs='12' md='7' >
                                {edit && !hideEdit ?
                                    <UserEdit /> :
                                    <UserInfo />}
                            </Col>
                        </Row>}
                    {/* {!edit &&
                    <Row className='submit-container'>
                        <CheckInButton onClick={() => printerProvider.handlePrintLabel()} />

                    </Row>
                } */}

                </div>
            </div >
        </>
    );
}

export default Component;
