import * as React from 'react'
import { FC, useEffect, useState, Fragment } from 'react'
import { Alert, Button } from "react-bootstrap";
import { Answers, Question } from "../../types/Questions";
// import dataController, { FetchArrayQuestionResponse, FetchArrayResponse, FetchPassStatusResponse, FetchPersonStatusResponse, PostAttestationsResponse } from "../../api/DataController";
import { PageError } from "../../types/PageErrors";
import { AnswerServer } from "../../types/Attestation";

import Loading from '../Loading';
import AttestationController from '../../api/controller/AttestationController';
import PersonController from '../../api/controller/PersonController';
import { useAppContext } from '../../context/AppContext';

export interface QuestionsProps {
}

const attestationController = new AttestationController();
const personController = new PersonController();

const QuestionsComponent: FC<QuestionsProps> = () => {
    const [page1Error, setPage1Error] = useState<boolean>(false)
    const [page2ErrorText, setPage2ErrorText] = useState<string>('')
    const [page2Error, setPage2Error] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [answers, setAnswers] = useState<Answers>({})
    const [questions, setQuestions] = useState<Question[]>([])
    const [page1Questions, setPage1Questions] = useState<Question[]>([])
    const [pageError, setPageError] = useState<PageError>({ error: false, errorText: '' })
    // eslint-disable-next-line
    const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false)
    const [showStep1, setShowStep1] = useState<boolean>(true)
    const [showStep2, setShowStep2] = useState<boolean>(false)

    const [CONTACT_KEY, setContactKey] = useState<string>('')
    const [TESTED_KEY, setTestedKey] = useState<string>('')
    const [IS_MEDICAL_KEY, setMedicalKey] = useState<string>('')

    const { user, setUser, setMessages, setShowAttestationDialog }: any = useAppContext();

    //initialize answers only once
    useEffect(() => {
        attestationController.getQuestions()
            .then(response => {
                setIsLoading(false)
                if (response) {
                    setQuestions(response)
                    setPage1Questions(response.filter((question: Question) => question.page === 1))

                    setContactKey(response.filter((q: Question) => q.short_text === 'Contact')[0].id)
                    setTestedKey(response.filter((q: Question) => q.short_text === 'Tested positive')[0].id)

                    let contactQ = getQuestionByKey(CONTACT_KEY, response)

                    setMedicalKey(contactQ.follow_up_questions!.filter((q: Question) => q.short_text === 'Health Care Worker')[0].id)
                } else {
                    setPageError({ error: true, errorText: 'An error occurred loading initial questions.' })
                }
            })
        // eslint-disable-next-line
    }, [])

    const getQuestionByKey = (key: string, arr: Question[]): Question => {
        // eslint-disable-next-line
        return arr.filter((q: Question) => q.id == key)[0]
    }

    const isDisqualifyingQuestion = (key: string) => {
        // eslint-disable-next-line
        let question = questions.filter((q: Question) => q.id == key)[0]
        return question && question.style === 'bad'
    }

    const followUpCountersNegativeAnswer = (key: string) => {
        // eslint-disable-next-line
        let question = questions.filter((q: Question) => q.id == key)[0]
        //if no follow ups, pass through
        if (question.follow_up_questions && question.follow_up_questions.length > 0) {
            return question.follow_up_questions && question.follow_up_questions.filter((f: Question) => !answers[f.id]).length > 0
        } else {
            return true
        }
    }

    const getHasSymptom = (testAnswers: Answers): boolean => {
        //filter any answers that are true, are disqualifying, but are not countered by the follow up
        return Object.keys(testAnswers).filter((key: string) => isDisqualifyingQuestion(key) && testAnswers[key] === true && followUpCountersNegativeAnswer(key)).length > 0
    }

    const updateAnswer = (id: string, newValue: boolean) => {
        let newAnswers = {
            ...answers,
            [id]: newValue
        }

        setAnswers(newAnswers)
    }

    const renderQuestion = (question: Question, value: boolean, invertColors: boolean = false): JSX.Element => {
        const { id, text } = question

        return <Fragment key={`render-q-${question.id}-${question.page}`}>
            <div className={'single-question'}>
                <div className={'question-text'}>
                    {text}
                </div>

                <div className={'yes-no-buttons'}>
                    <div className={`y-n-button ${value === false ? 'checked' : 'unchecked'} ${invertColors ? 'red' : 'green'}`} onClick={() => (updateAnswer(id, false))}>
                        {!invertColors && value === false && <img alt='check' src={'/assets/glyphicons-basic-739-check.svg'} className={'check'} />}
                        {invertColors && value === false && <img alt='check' src={'/assets/times.svg'} className={'times'} />}
                        {value !== false && <span>No</span>}
                    </div>
                    <div className={`y-n-button ${value === true ? 'checked' : 'unchecked'} ${invertColors ? 'green' : 'red'}`} onClick={() => (updateAnswer(id, true))}>
                        {!invertColors && value === true && <img alt='check' src={'/assets/times.svg'} className={'times'} />}
                        {invertColors && value === true && <img alt='check' src={'/assets/glyphicons-basic-739-check.svg'} className={'check'} />}
                        {value !== true && <span>Yes</span>}
                    </div>
                </div>
            </div>
            {answers[id] && question.follow_up_questions && question.follow_up_questions.map((followUpQ: Question) => {
                return renderQuestion(followUpQ, answers[followUpQ.id], true)

            })}
        </Fragment>
    }

    const validateAttestations = (): boolean => {
        // if you are on step 1, all questions must be answered
        let valid = true
        if (showStep1) {
            valid = Object.keys(answers).length === page1Questions.length
        }
        return valid
    }
    // eslint-disable-next-line
    const cancelSubmit = () => {
        setShowConfirmationDialog(false)
    }

    const submitAttestations = (callbackFn?: () => void) => {
        if (!validateAttestations()) {
            setPage1Error(true)
            return
        }

        setIsLoading(true);

        //show confirmation dialog if it's not already visible
        // if (!showConfirmationDialog) {
        //     setShowConfirmationDialog(true)
        //     return
        // }

        //convert web attestations to server format
        const answersServer: AnswerServer[] = Object.keys(answers).map((questionKey: string) => {
            return {
                id: questionKey,
                checked: answers[questionKey]
            }
        })

        let body = {
            answers: answersServer
        }

        attestationController.postAttestation(body, user.krb_name)
            .then(data => {
                return data;
            })
            .then(data => {
                personController.getPersonInfo(user.krb_name, 'location')
                    .then(data => {
                        setUser(data);
                        setIsLoading(false);
                        let status = data.status.statusVal;
                        setMessages((state: any) => ({
                            ...state, user: {
                                id: 1,
                                type: status === "medical_symptoms" ? 'danger' : 'success',
                                message: `${status === "medical_symptoms" ? `Symptoms reported. Inform ${user.displayName} to self-isolate as a precaution. An MIT Medical clinician will contact them as soon as possible. They will be contacted in the order of information received.` : `Attestation submitted for ${user.displayName}.`}`
                            }
                        }));
                        setShowAttestationDialog(false);
                    })
            })
            .catch(error => {

            })

        // dataController.postAttestations({
        //     answers: answersServer,
        //     start_time: toUtcDate(mainAppState!.onCampusStartTime!),
        //     end_time: toUtcDate(mainAppState!.onCampusEndTime!),
        // }, mainAppState!.submittingOnBehalfKerbId)
        //     .then((pr: PostAttestationsResponse) => {
        //         setShowConfirmationDialog(false)
        //         dataController.fetchPassStatus(undefined, mainAppState!.submittingOnBehalfKerbId).then((statusResponse: FetchPassStatusResponse) => {
        //             mainAppState!.updatePersonStatus(statusResponse)
        //             history.push('/')
        //         })
        //     })
    }

    const handleResetSymptoms = () => {
        setAnswers({})
    }

    // const noToAll = () => {
    //     let newObj = {}
    //     page1Questions.forEach((q: Question) => (newObj[`${q.id}`] = false))
    //     setAnswers(newObj)
    // }
    // eslint-disable-next-line
    const resetSymptoms = () => {
        let newObj = { ...answers }
        Object.keys(newObj).forEach((key: string) => {
            newObj[key] = false
        })
        return newObj
    }
    // eslint-disable-next-line
    const getModalText = () => {
        if (getHasSymptom(answers)) {
            if (answers[TESTED_KEY] && answers[CONTACT_KEY]) {
                return 'You will be submitting that you have been tested for COVID-19 and had a positive result, or have been told by a healthcare provider that you are likely positive for COVID-19, and that within the last 14 days, you have been in close contact with someone that you know had been diagnosed with COVID-19 or had experienced COVID-19 related symptoms.'
            }
            if (answers[TESTED_KEY]) {
                return `You will be submitting that you have been tested for COVID-19 and had a positive result, or have been told by a healthcare provider that you are likely positive for COVID-19.`
            }
            if (answers[CONTACT_KEY]) {
                return `You${answers[IS_MEDICAL_KEY] ? ' are a healthcare worker that' : ''} will be submitting that within the last 14 days, you have been in close contact with someone that you know had been diagnosed with COVID-19 or had experienced COVID-19 related symptoms.`
            }
            return "You will be submitting that you do exhibit symptoms related to COVID-19."
        } else {
            return "You will be submitting that you do not exhibit symptoms related to COVID-19."
        }
    }

    const showSubmitButton = (): boolean => {
        return showStep1 && getHasSymptom(answers)
    }

    const showContinueButton = (): boolean => {
        //continue button is visible if:
        //there is no symptom, and answer that no symptom has been given
        return showStep1 && !getHasSymptom(answers)
    }

    const continueToQuestion2 = () => {
        if (!validateAttestations()) {
            setPage1Error(true)
            return
        } else {
            setPage1Error(false)
            setShowStep1(false)
            setShowStep2(true)
        }
    }

    const countVisibleQuestions = () => {
        let count = 0
        //count each question
        questions.forEach((q: Question) => {
            count++
            //count all follow up questions
            if (answers[q.id] && q.follow_up_questions && q.follow_up_questions.length > 0) {
                count = count + q.follow_up_questions.length
            }
        })
        return count
    }

    const submitAdditionalQuestions = () => {
        let allRequiredQuestionsAnswered = true
        let page2ErrorTextTmp = ''

        const answersLength = Object.keys(answers).length
        const visibleQ = countVisibleQuestions()
        if (visibleQ > answersLength) {
            allRequiredQuestionsAnswered = false
            page2ErrorTextTmp = 'Please answer all the questions and submit again.'
        }

        //make sure the ppe and contact question is answered YES
        questions.filter((q: Question) => (q.page === 2)).forEach((question: Question) => {
            if (question.required && question.style === 'good' && !answers[question.id]) {
                allRequiredQuestionsAnswered = false
            }
        })

        if (!allRequiredQuestionsAnswered) {
            // eslint-disable-next-line
            if (page2ErrorTextTmp == '')
                page2ErrorTextTmp = 'In order to obtain campus access, you must agree to wear Personal Protective Equipment (PPE) and adhere to all rules and protocols of social distancing. Please review your answers.'
            setPage2ErrorText(page2ErrorTextTmp)
            setPage2Error(true)
        } else {
            setPage2Error(false)
            setPage2ErrorText('')
            submitAttestations()
        }
    }

    const handleSubmitAttestations = () => {
        submitAttestations()
    }
    // eslint-disable-next-line
    const shouldShowTimeEntry = () => {
        return !getHasSymptom(answers)
    }

    return <>
        <div><Loading loaded={isLoading} />
            {pageError.error && <div>{pageError.errorText}</div>}
            {/* <Loader loaded={!isLoading} options={mediumLoaderOptions}> */}
            {!pageError.error && <div className={'question-container'}>
                {showStep1 && <>

                    {/* {mainAppState!.lastSubmission && <div className={'last-submission-banner'}>Your last submission was {displayDateTime(mainAppState!.lastSubmission)}</div>} */}
                    {/* <div className={'symptoms-header'}>
                        <p className={'symptoms-header-question'}>Do you experience any of the following symptoms?</p>
                    </div> */}


                    {page1Questions.map((question: Question, index: number) => {
                        return <Fragment key={`main-q-${question.id}-${index}`}>
                            {renderQuestion(question, answers[question.id])}
                        </Fragment>
                    })}</>}

                {showStep2 && <div>
                    {questions.filter((q: Question) => q.page === 2).map((question: Question, index: number) => {
                        return <Fragment key={'follow-up-' + question.id + '-' + index}>
                            {renderQuestion(question, answers[question.id], question.style === 'good')}
                        </Fragment>
                    })}

                    {page2Error && <Alert variant={'danger'} className={'smallMarginTop'}>{page2ErrorText}</Alert>}
                    <div className='mt-1'>
                        {<Button variant="light" size="lg" className={'submit-button'} onClick={submitAdditionalQuestions}>
                            Submit
                    </Button>}
                    </div>
                </div>}

                {page1Error && <Alert variant={'danger'} className={'smallMarginTop'}>Please review your answers and try again.</Alert>}

                <div className='mt-1'>
                    {showSubmitButton() && <Button variant="light" size="lg" className={'submit-button'} onClick={handleSubmitAttestations}>
                        Submit
                </Button>}
                    {showContinueButton() && <Button variant="light" size="lg" className={'submit-button'} onClick={continueToQuestion2}>
                        Continue
                </Button>}

                    {/*{process.env.ENV === 'local' && <a href={'#'} onClick={noToAll} className={'reset-button'}>no to all</a>}*/}

                    {showStep1 &&
                        // eslint-disable-next-line
                        <a onClick={handleResetSymptoms} className={'reset-button'}>reset</a>}
                </div>
            </div>}
            {/* </Loader> */}
            {/* {showConfirmationDialog && <ConfirmationModal handleSubmit={submitAttestations} handleCancel={cancelSubmit} text={getModalText()} showTimeEntry={shouldShowTimeEntry()} />} */}
        </div>
    </>
}

export default QuestionsComponent;