import React from 'react';

import { useAppContext } from '../context/AppContext';

import Switch from '@material-ui/core/Switch';
import LocalStorage from '../common/LocalStorage';

interface Props {

}

const Component: React.FC<Props> = () => {
    const { scanType, setScanType }: any = useAppContext();
    const localStorage = new LocalStorage();


    const handleSwitchChange = (event: any) => {
        let scanType = event.target.checked ? 'self' : 'medical';
        setScanType(scanType);
        localStorage.save('scan_type', scanType);
    }

    return (
        <div className='scan-switch'>
            <span>Medical Test</span>
            <Switch onClick={handleSwitchChange} checked={scanType !== 'medical'} />
            <span>Self Test</span>
        </div>
    )


}

export default Component;
