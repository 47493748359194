import React from 'react';

interface Props {
    message: string
}

const Component: React.FC<Props> = ({ message }) => {

    return (

        <div className='splash-screen'>
            <div>
                <img src="./assets/MIT_logo.png" style={{ maxWidth: "150px" }} alt="loading" />
                <h2 className='mt-2'>Covid Medical Checkin</h2>
                <h6 className='mt-2'>{message}</h6>
            </div>
        </div>
    )


}

export default Component;
