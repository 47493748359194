import React, { Fragment } from 'react';

import ValidationError from './ValidationError';

import { KeyboardDatePicker } from "@material-ui/pickers";



interface Props {
    onChange: any
    placeholder?: string
    value: any
    className?: string
    errorText?: string
}

const Component: React.FC<Props> = ({ className, errorText, onChange, placeholder, value }) => {

    const onValueChange = (date: any) => {
        let dateString = null;
        if (date !== null && date.isValid()) {
            dateString = date.format().split('T')[0];
        }

        if (onChange) {
            onChange(dateString);
        }
    }

    return (
        <Fragment>
            <KeyboardDatePicker
                autoOk
                value={value}
                placeholder={placeholder ? placeholder : ' '}
                onChange={onValueChange}
                // minDate={new Date()}
                format="MM/DD/YYYY"
                variant="inline"
                className={'date-picker ' + className ? className : ''}
            />
            <ValidationError text={errorText} />
        </Fragment>
    );

}

export default Component;
