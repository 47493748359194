import React, { Fragment } from 'react';

import ValidationError from './ValidationError';

import InputMask from 'react-input-mask';


interface Props {
    onChange: any
    mask: any
    value: any
    placeholder?: string
    className?: string
    errorText?: string
}

const Component: React.FC<Props> = ({ className, errorText, onChange, placeholder, value, mask }) => {

    const onValueChange = (e: any) => {
        let value = e.target.value;

        if (value) {
            value = value.split('(').join('').split(')').join('').split('-').join('').split('_').join('');;
        }

        if (onChange) {
            onChange(value);
        }
    }

    return (
        <Fragment>
            <InputMask mask={mask} maskChar={'_'} value={value} onChange={onValueChange} className={'input-mask ' + className ? className : ''} />
            <ValidationError text={errorText} />
        </Fragment>
    );

}

export default Component;
